/**
 * LayoutComponent component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import { FooterComponent } from "./footer.component"


const LayoutComponent = ({ children }) => {
  return (
    <>
      <main>{children}</main>
      <FooterComponent/>
    </>
  )
}

LayoutComponent.propTypes = {
  children: PropTypes.node.isRequired,
}

export default LayoutComponent
