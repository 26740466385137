import React from "react"
import { Html } from "../common/html.component"
import { Script } from "../common/script.component"

export const ScriptSlice = ({ slice }) => {
  return (
    <>
      {slice.fields.map(item => (
        <>
          <Html execOnce={true} html={item.script_html} />
          <Script scriptUrl={item.script_url} />
        </>
      ))}
    </>
  )
}
