import React from "react"
import { CustomLink } from "./custom-link.component"
import { getLinkClass } from "../../utils/styles"
import { RichText } from "./rich-text.component"
import { Image } from "./image.component"

export const LinksRow = ({ links, className, title }) => {
  return (
    <div className={className}>
      <RichText
        render={title}
        className="border-bottom-dashed border-bottom-bold pb-2"
      />
      <ul>
        {links.map(item => (
          <li className="nav-item links-row-item mt-4 font-15">
            <div className="d-inline-block">
              <Image className="d-inline-block" image={item.icon} />
            </div>
            <CustomLink
              link={item.link}
              activeClassName="active"
              className={getLinkClass(item.link_style, "nav-link")}
            >
              {item.link_text}
            </CustomLink>
          </li>
        ))}
      </ul>
    </div>
  )
}
