import React from "react"
import { Section } from "../common/section.component"
import { Html } from "../common/html.component"
import { Script } from "../common/script.component"
import { RichText } from "../common/rich-text.component"

export const Form = ({ slice }) => {
  const bgImage = slice.primary && slice.primary.bg_image
  const bgColor = slice.primary && slice.primary.bg_color
  return (
    <Section
      className="py-5"
      backgroundImage={bgImage}
      backgroundColor={bgColor}
    >
      <div className="block-with-image-block text-center">
        <div className="mb-5">
          <RichText render={slice.primary.title} />
          <RichText render={slice.primary.text} />
        </div>
        <div className="form-content">
          <Html html={slice.primary.form_script} />
          <Script scriptUrl={slice.primary.form_url} />
        </div>
      </div>
    </Section>
  )
}
